function showOnlinePaymentDiv() {
  var divOnlinePayment = document.getElementById("pay-online-form");
  if(divOnlinePayment.style.display == "block") {
    divOnlinePayment.style.display = "none";
  } else {
    divOnlinePayment.style.display = "block";
  }
}


function checkInstitution(value) {
  var divInstitutionMembers = document.getElementById("institution-members");
  var spanName = document.getElementById("name-span");
 
  if(value == "Institution") {
    divInstitutionMembers.style.display = "block";
    spanName.innerHTML = "Institution name";
   } else {
     divInstitutionMembers.style.display = "none";
     spanName.innerHTML = "Name";

  }
}

function calculateAmount(value) {
  var inputContribution = document.getElementById("contribution-input");
  if((value == "") || (value == 0)) {
    value = 1;
  }
  var amount = parseInt(value) * 75;
  if(amount > 250) {
    amount = 250;
  }
  inputContribution.value = amount + ".00";
}